// RealTimeTranscription.js
import React, { useEffect, useState } from 'react';
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition';
import { Button, Dropdown } from '../reusableComponents';
import {
    faDeleteLeft,
    faGear,
    faMicrophone,
    faMicrophoneSlash,
} from '@fortawesome/free-solid-svg-icons';
import { toSentenceCase } from '../../utils';
import { supportedLanguages } from '../../languages';

export const RealTimeTranscription = ({
    language,
    setLanguage,
    onTranscription,
    onTranscriptionReset,
    isListening,
    setIsListening,
    activeAnswer,
    enableSpeaker,
    setEnableSpeaker,
    setActiveAudioId,
    isSmallScreen,
}) => {
    const { transcript, resetTranscript, listening } = useSpeechRecognition();
    const [languageDropdown, setLanguageDropdown] = useState([]);

    useEffect(() => {
        const languageDropDown = Object.keys(supportedLanguages).map((lang) => {
            const data = {
                key: lang,
                label: toSentenceCase(lang),
                icon: null,
                iconColor: null,
                selected: language === lang,
                action: () => {
                    setLanguage(lang);
                },
            };
            return data;
        });

        setLanguageDropdown(languageDropDown);
    }, [language]);

    // Sync transcript with liveTranscript state in real-time
    useEffect(() => {
        if (transcript && onTranscription) {
            onTranscription(transcript); // Update live transcript as speech recognition runs
        }
    }, [transcript]);

    useEffect(() => {
        if (isListening) {
            handleStart();
        } else {
            handleStop();
        }
    }, [isListening, language]);

    useEffect(() => {
        resetTranscript();
    }, [activeAnswer, language]);

    // Start recording and listening in Hindi
    const handleStart = () => {
        if (!isListening) {
            setIsListening(true);
            setActiveAudioId(null);
        }

        SpeechRecognition.startListening({
            continuous: true,
            language: supportedLanguages[language],
        });

        setTimeout(() => {
            setEnableSpeaker(true);
        }, 500);
    };

    // Stop recording and stop listening
    const handleStop = () => {
        if (isListening) {
            setIsListening(false);
        }

        SpeechRecognition.stopListening();
        onTranscription(transcript);
    };

    // Reset the transcript (optional)
    const handleReset = () => {
        resetTranscript();
        if (onTranscriptionReset) {
            onTranscriptionReset();
        }
    };

    return (
        <div className='flex items-center'>
            {!isListening && (
                <div className='flex items-center'>
                    {/* <Dropdown
                        dropDownList={languageDropdown}
                        showButtonText={!isSmallScreen}
                        buttonText={
                            isSmallScreen ? null : toSentenceCase(language)
                        }
                        titleText={null}
                        buttonHoverText={'Select Audio Language'}
                        buttonClassName={`text-texts-tertiary400 mr-1 h-8 ${
                            isSmallScreen ? 'w-fit' : 'min-w-32'
                        }`}
                        icon={isSmallScreen ? faGear : null}
                        listClassName={''}
                        listTitleText={'Supported Languages'}
                        disabled={isListening}
                        placement={'top'}
                    /> */}
                    <Button
                        text={null}
                        className={``}
                        hoverText='Unmute mic to start listening to your query'
                        disabled={isListening}
                        onClick={handleStart}
                        icon={faMicrophoneSlash}
                        type={'success'}
                    />
                </div>
            )}
            {isListening && (
                <div className='flex border rounded-full'>
                    <Button
                        text={null}
                        className={`mr-1`}
                        hoverText='Clear your question input'
                        disabled={!isListening}
                        onClick={handleReset}
                        icon={faDeleteLeft}
                        type={'danger'}
                    />

                    <Button
                        text={null}
                        className={`ml-1 animate-pulse`}
                        hoverText='Mute mic to stop Listening to your query'
                        disabled={!isListening}
                        onClick={handleStop}
                        icon={faMicrophone}
                        type={'danger'}
                    />
                </div>
            )}
        </div>
    );
};
