import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserAlt,
    faRobot,
    faPencil,
    faComments,
    faDatabase,
    faClipboardList,
    faEllipsisV,
    faArrowRight,
    faIcons,
    faBars,
    faAngleRight,
    faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    Dropdown,
    ExpandableTable,
    GeneratingView,
} from '../reusableComponents';
import { Modal } from '../reusableComponents';
import { List } from 'antd';
import { ChatFormatType } from '../../constants/chatFormatType';
import { isValidJsonString } from '../../utils';
import { RepoType } from '../../constants/repoType';
import { DataType } from '../../constants';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import Lottie from 'react-lottie';
import generatingAnimation from '../../lotties/generatingAnimation.json';
import loadingAnimation from '../../lotties/LoadingAnimation.json';
import { TextReader } from '../AudioPlayer';

const generatingOptions = {
    loop: true,
    autoplay: true,
    animationData: generatingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const NoChatView = ({
    isGenerating,
    isSmallScreen,
    selectedProductCategory,
    selectedProduct,
    hasProductCategories,
    hasProducts,
}) => {
    const size = isSmallScreen ? 350 : 400;
    const width = isSmallScreen ? 340 : 1200;
    const height = isSmallScreen ? 100 : 250;

    if (isGenerating) {
        return (
            <div className='w-full h-full flex flex-col items-center justify-between drop-shadow-sm px-2'>
                <div></div>
                <Lottie
                    options={generatingOptions}
                    height={size}
                    width={size}
                />

                <div className='w-full flex items-center justify-center relative'>
                    <div className='w-full flex items-center justify-center absolute bottom-0'>
                        <Lottie
                            options={loadingOptions}
                            height={height}
                            width={width}
                        />
                    </div>
                    <GeneratingView text={'Generating Answer'} />
                </div>
            </div>
        );
    }

    return (
        <div className='flex flex-col h-full w-full items-center justify-center relative select-none'>
            <div
                className={`flex ${
                    isSmallScreen ? 'flex-col scale-90' : ''
                } justify-center items-center`}
            >
                {!selectedProductCategory && hasProductCategories && (
                    <>
                        <div className='flex flex-col justify-center items-center'>
                            <FontAwesomeIcon
                                icon={faIcons}
                                className='text-icons-primary w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2'
                            />
                            <div className='flex justify-center w-fit text-texts-tertiary300 font-semibold pt-3 lg:text-base md:text-base sm:text-sm'>
                                <span className='pr-1'>Select</span>
                                <span>Category</span>
                            </div>
                        </div>

                        <FontAwesomeIcon
                            icon={isSmallScreen ? faArrowDown : faArrowRight}
                            className='text-icons-tertiary100 w-6 h-6 md:w-16 md:h-16 lg:w-18 lg:h-18 lg:m-8 md:m-4 m-2'
                        />
                    </>
                )}

                {!selectedProduct && hasProducts && (
                    <>
                        <div className='flex flex-col justify-center items-center'>
                            <FontAwesomeIcon
                                icon={faProductHunt}
                                className='text-icons-primary w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2'
                            />
                            <div className='flex justify-center w-fit text-texts-tertiary300 font-semibold pt-3 lg:text-base md:text-base sm:text-sm'>
                                <span className='mr-1'>Select</span>{' '}
                                <span>Product</span>
                            </div>
                        </div>
                        <FontAwesomeIcon
                            icon={isSmallScreen ? faArrowDown : faArrowRight}
                            className='text-icons-tertiary100 w-6 h-6 md:w-16 md:h-16 lg:w-18 lg:h-18 lg:x-8 md:m-4 m-2'
                        />
                    </>
                )}

                <div className='flex flex-col justify-center items-center'>
                    <FontAwesomeIcon
                        icon={faComments}
                        className='text-icons-primary w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 mx-2'
                    />
                    <div className='flex justify-center w-fit text-texts-tertiary300 font-semibold pt-3 lg:text-base md:text-base sm:text-sm'>
                        Chat
                    </div>
                </div>
            </div>

            <div className='w-full flex items-center justify-center text-texts-secondary300 mt-6 p-2'>
                <div className='p-2 w-fit'>
                    Start asking questions
                    {(!selectedProductCategory || !selectedProduct) &&
                        (hasProductCategories || hasProducts) && (
                            <> by selecting </>
                        )}
                    {!selectedProductCategory && hasProductCategories && (
                        <>
                            <span className='font-semibold break-keep'>
                                'Product Category'
                            </span>{' '}
                        </>
                    )}
                    {!selectedProduct && hasProducts && (
                        <>
                            and{' '}
                            <span className='font-semibold break-keep'>
                                'Product'
                            </span>{' '}
                            from top right
                        </>
                    )}
                    {isSmallScreen &&
                    (!selectedProductCategory || !selectedProduct) &&
                    (hasProductCategories || hasProducts) ? (
                        <>
                            <FontAwesomeIcon
                                icon={faBars}
                                className='text-backgrounds-primary200 mx-2'
                            />
                            icon
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

const getTime = (timestamp) => {
    const timeText = timestamp.split(',')[1];
    const timeData = timeText.split(':');
    const hours = timeData[0];
    const mins = timeData[1];
    const amPm = timeText.split(' ')[2];
    return `${hours}:${mins} ${amPm ? amPm : ''}`;
};

const splitDate = (date) => {
    const parts = date.split('/');
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const year = parseInt(parts[2]);
    return {
        day,
        month,
        year,
    };
};

const getDate = (timestamp) => {
    const date = timestamp.split(',')[0];
    const parts = date.split('/');
    const dateFormatted = parts[1] + '/' + parts[0] + '/' + parts[2];
    const dateObj = splitDate(dateFormatted);

    const dateNow = new Date().toLocaleDateString('en-GB').toString();
    const dateNowObj = splitDate(dateNow);

    if (
        dateNowObj.year === dateObj.year &&
        dateNowObj.month === dateObj.month &&
        dateNowObj.day === dateObj.day
    )
        return 'Today';
    if (
        dateNowObj.year === dateObj.year &&
        dateNowObj.month === dateObj.month &&
        dateNowObj.day - dateObj.day === 1
    )
        return 'Yesterday';

    return date;
};

const QuestionActionView = ({ user, chatObject, setQuery }) => {
    return (
        <div className='w-full ml-auto flex justify-between items-center border-b border-white pb-2 mb-2'>
            <div className='flex items-center justify-between text-texts-secondary300 truncate'>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-backgrounds-white mr-2'>
                    <FontAwesomeIcon
                        icon={faUserAlt}
                        className='text-sky-300 drop-shadow'
                    />
                </div>
                <div className='font-semibold truncate'>
                    {user.userName ? user.userName : 'You'}
                </div>
            </div>
            {/* Action Section */}
            <div className='text-texts-secondary300'>
                <button
                    onClick={() => {
                        setQuery(chatObject.question);
                    }}
                    title='Edit the question in chat box'
                    className='ml-4 hover:scale-110'
                >
                    <FontAwesomeIcon icon={faPencil} />
                </button>
            </div>
        </div>
    );
};

const QuestionView = ({ chatObject }) => {
    return (
        <>
            <div className='text-slate-700'>{chatObject.question}</div>
            <div className='flex justify-between items-between text-xs mt-2 text-texts-secondary300'>
                <div>{getDate(chatObject.timestamp)}</div>
                <div>{getTime(chatObject.timestamp)}</div>
            </div>
        </>
    );
};

const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

const DisplayText = ({ text, maxLength }) => (
    <div className='font-semibold'>{truncateText(text, maxLength)}</div>
);

const AnswerActionView = ({
    chatObject,
    user,
    setViewDatabaseQuery,
    setActiveDbQuery,
    isLastAnswer,
    isAdmin,
    isSmallScreen,
    activeAudioId,
    setActiveAudioId,
    audioUrlData,
    enableAudioFeature,
    enableReader,
    enableSpeaker,
    setEnableSpeaker,
    setEnableScrollToBottom,
}) => {
    const [sqlQuery, setSqlQuery] = useState('');
    const [showQueries, setShowQueries] = useState(false);
    let productCategory = null;
    let product = null;

    if (Array.isArray(chatObject.productCategory)) {
        productCategory = null;
    } else {
        productCategory = chatObject.productCategory;
    }

    if (Array.isArray(chatObject.product)) {
        product = null;
    } else {
        product = chatObject.product;
    }

    const dropdownRef = useRef(null);
    let userSpecificActionList = [
        // {
        //     label: 'Show in tabular format',
        //     icon: faTable,
        //     //iconColor: 'sky',
        //     action: () => handleTableFormat(),
        // },
        // {
        //     label: 'Show in List format',
        //     icon: faList,
        //     //iconColor: 'sky',
        //     action: () => handleListFormat(),
        // },
    ];

    useEffect(() => {
        let isMounted = true; // Track whether the component is still mounted

        const loadResources = async () => {
            if (!chatObject.qid) return;

            // Load SQL query
            const query = chatObject.sqlQuery;
            if (isMounted && query) {
                setSqlQuery(query);
            }
        };

        loadResources();

        return () => {
            isMounted = false; // Cleanup on unmount
        };
    }, [chatObject.qid, isAdmin, user]);

    const convertToQueriesArray = (queriesString) => {
        let queriesArray = [];
        try {
            let parsedQueries = queriesString;

            // Manual conversion of the string to array
            if (typeof parsedQueries === 'string') {
                // Remove the outer square brackets and split by comma
                parsedQueries = parsedQueries
                    .replace(/^\[|\]$/g, '') // Remove outer square brackets
                    .split(/,\s*(?=['"])/) // Split by commas between strings
                    .map(
                        (query) => query.replace(/^['"]|['"]$/g, '') // Remove quotes around each query
                    );
            }

            // If it's an array, assign it to queriesArray
            if (Array.isArray(parsedQueries)) {
                queriesArray = parsedQueries;
            }
        } catch (error) {
            console.error('Failed to parse queries:', error);
        }

        return queriesArray;
    };

    const [queriesArray, setQueriesArray] = useState([]);

    useEffect(() => {
        if (chatObject.queries) {
            const array = convertToQueriesArray(chatObject.queries);
            setQueriesArray(array);
        }
    }, [chatObject.queries]);

    return (
        <div className='w-full ml-auto flex justify-between items-center border-b border-white pb-2 mb-2'>
            <div className='flex items-center justify-between text-texts-secondary300 truncate'>
                <div>
                    {productCategory && product ? (
                        <div className='w-fit'>
                            <FontAwesomeIcon
                                icon={faIcons}
                                className='mr-2 drop-shadow-sm text-backgrounds-primary400'
                            />
                            <span className='overflow-ellipsis overflow-hidden font-semibold'>
                                {productCategory}
                            </span>

                            <FontAwesomeIcon
                                icon={faAngleRight}
                                className='mx-3 drop-shadow-sm text-texts-secondary300'
                            />
                            <FontAwesomeIcon
                                icon={faProductHunt}
                                className='mr-2 drop-shadow-sm text-backgrounds-primary400'
                            />
                            <span className='overflow-ellipsis overflow-hidden font-semibold mr-2'>
                                {product}
                            </span>
                        </div>
                    ) : (
                        <div className='flex items-center'>
                            <div className='flex items-center justify-center w-6 h-6 rounded-full bg-backgrounds-white mr-2'>
                                <FontAwesomeIcon
                                    icon={faRobot}
                                    className='text-backgrounds-primary400 drop-shadow'
                                />
                            </div>
                            <DisplayText
                                text={'Product Support'}
                                maxLength={28}
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Action Section */}
            <div className='flex items-center w-fit h-full text-texts-secondary300'>
                {chatObject.queries && isAdmin && (
                    <>
                        <button
                            className='ml-4 hover:scale-110'
                            onClick={() => setShowQueries(!showQueries)}
                            title='View related queries'
                        >
                            <FontAwesomeIcon
                                icon={faClipboardList}
                                style={{ fontSize: '18px' }}
                            />
                        </button>
                        {showQueries && (
                            <Modal
                                title={'Related Multi Queries'}
                                titleIcon={faClipboardList}
                                iconColor={'icons-primary'}
                                onClose={() => setShowQueries(false)}
                            >
                                <div className='w-96 text-texts-secondary300 p-3'>
                                    <div className='font-bold mb-2'>
                                        These are some multi queries that were
                                        asked from LLM:
                                    </div>
                                    <ul className='list-disc pl-5'>
                                        {queriesArray.map((query, index) => (
                                            <li key={index} className='mb-1'>
                                                {query}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Modal>
                        )}
                    </>
                )}

                {(chatObject.chunksData.length || sqlQuery) &&
                    typeof chatObject.answer === DataType.STRING && (
                        <div className='text-texts-secondary300'>
                            {sqlQuery && (
                                <button
                                    className='ml-4 hover:scale-110'
                                    onClick={() => {
                                        setViewDatabaseQuery(true);
                                        setActiveDbQuery(sqlQuery);
                                    }}
                                    title='View query'
                                >
                                    <FontAwesomeIcon icon={faDatabase} />
                                </button>
                            )}

                            {/* {!sqlQuery && isLastAnswer && !isSmallScreen && (
                                <>
                                    <button
                                        className='ml-4 hover:scale-110'
                                        onClick={handleTableFormat}
                                        disabled={!chatObject.answer}
                                        title='Convert answer into tabular format'
                                    >
                                        <FontAwesomeIcon icon={faTable} />
                                    </button>
                                    <button
                                        className='ml-4 hover:scale-110'
                                        onClick={handleListFormat}
                                        disabled={!chatObject.answer}
                                        title='Convert answer into list format'
                                    >
                                        <FontAwesomeIcon icon={faList} />
                                    </button>
                                </>
                            )} */}
                        </div>
                    )}

                {/* Dropdown for small screens */}
                {!sqlQuery && isLastAnswer && isSmallScreen && (
                    <div
                        className='relative inline-block text-left'
                        ref={dropdownRef}
                    >
                        <Dropdown
                            dropDownList={userSpecificActionList}
                            icon={<FontAwesomeIcon icon={faEllipsisV} />}
                            showButtonText={false}
                            buttonText={null}
                            buttonHoverText={'More options'}
                            buttonClassName={
                                'ml-4 hover:scale-110 focus:outline-none focus:outline-none'
                            }
                            listClassName={''}
                            backgroundColor={''}
                            textColor={'green'}
                            disabled={false}
                            customClass={true}
                            placement={'left'}
                        />
                    </div>
                )}

                {/* Text-To-Speech feature */}
                {enableAudioFeature && (
                    <TextReader
                        id={chatObject.qid}
                        activeId={activeAudioId}
                        audioURLArray={audioUrlData[chatObject.qid]}
                        enableSpeaker={enableSpeaker}
                        setEnableSpeaker={setEnableSpeaker}
                        enableReader={enableReader}
                        setActiveAudioId={setActiveAudioId}
                        setEnableScrollToBottom={setEnableScrollToBottom}
                    />
                )}
            </div>
        </div>
    );
};

const AnswerView = ({ chatObject, isDatabase, isSmallScreen }) => {
    const pageSize = isSmallScreen ? 3 : 5;
    let pagination = false;
    let answerDisplayComponent = null;

    if (chatObject) {
        if (
            isDatabase === RepoType.SQLLITE ||
            isDatabase === RepoType.POSTGRESS
        ) {
            // Database repositories

            let answer = chatObject.answer;

            if (isValidJsonString(answer)) {
                answer = JSON.parse(answer);
            } else {
                answer = chatObject.answer;
            }

            if (answer.type === ChatFormatType.HTML) {
                answerDisplayComponent = (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: answer.payload ? answer.payload : answer,
                        }}
                        className='px-1'
                    />
                );
            } else if (answer.type === ChatFormatType.STRING) {
                answerDisplayComponent = chatObject.answer.payload
                    ? chatObject.answer.payload
                    : chatObject.answer;
            } else if (answer.type === ChatFormatType.TABLE) {
                const columnData = answer.payload.columns;
                const tableData = answer.payload.data;

                answerDisplayComponent = (
                    <ExpandableTable
                        columnData={columnData}
                        tableData={tableData}
                        pageSize={pageSize}
                        pagination={pagination}
                        isSmallScreen={isSmallScreen}
                        completeData={answer.payload.completeData}
                    />
                );
            } else {
                answerDisplayComponent = chatObject.answer;
            }
        } else {
            // Non database repositories
            if (chatObject.type === ChatFormatType.TABLE) {
                const columnData = chatObject.answer.tableHead;

                const tableData = chatObject.answer.tableData;
                const tableFormattedData = tableData.map((rowData) =>
                    Object.values(rowData)
                );

                answerDisplayComponent = (
                    <ExpandableTable
                        columnData={columnData}
                        tableData={tableFormattedData}
                        pageSize={pageSize}
                        pagination={pagination}
                        isSmallScreen={isSmallScreen}
                    />
                );
            } else if (chatObject.type === ChatFormatType.LIST) {
                let data = [];

                if (chatObject.answer.listData) {
                    if (Array.isArray(chatObject.answer.listData)) {
                        data = chatObject.answer.listData;
                    } else {
                        data = [chatObject.answer.listData.toString()];
                    }
                } else {
                    data = [chatObject.answer.toString()];
                }

                answerDisplayComponent = (
                    <List
                        size='small'
                        dataSource={data}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                );
            } else if (chatObject.type === ChatFormatType.MULTIPLE) {
                let title = '';
                let data = [];

                if (!Array.isArray(chatObject.answer)) {
                    title = 'Multiple Answers';
                    data = [chatObject.answer.toString()];
                } else {
                    title = `${chatObject.answer.length} Answer${
                        chatObject.answer.length > 1 ? 's' : ''
                    }`;
                    data = chatObject.answer;

                    if (chatObject.answer.length > 1) {
                        pagination = { pageSize: pageSize };
                    }
                }

                answerDisplayComponent = (
                    <List
                        header={
                            <div className='text-texts-secondary300 font-semibold text-center w-full'>
                                {title}
                            </div>
                        }
                        size='small'
                        dataSource={data}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                        pagination={pagination}
                    />
                );
            } else if (chatObject.type === ChatFormatType.CUSTOM) {
                if (typeof chatObject.answer === ChatFormatType.STRING) {
                    answerDisplayComponent = chatObject.answer;
                } else {
                    answerDisplayComponent = chatObject.answer.toString();
                }
            } else {
                if (typeof chatObject.answer === ChatFormatType.STRING) {
                    answerDisplayComponent = chatObject.answer;
                } else {
                    answerDisplayComponent = chatObject.answer.toString();
                }
            }
        }

        return (
            <div className='w-full'>
                <div className='w-full text-slate-700'>
                    {answerDisplayComponent}
                </div>
                <div className='w-full flex justify-between items-center text-xs mt-2 text-texts-secondary300'>
                    <div>{getDate(chatObject.timestamp)}</div>
                    <div>{getTime(chatObject.timestamp)}</div>
                </div>
            </div>
        );
    }
};

export const ChatDisplay = ({
    activeQuestion,
    questionsData,
    setQuery,
    user,
    isGenerating,
    isDatabase,
    selectedProductCategory,
    selectedProduct,
    isSmallScreen,
    hasProductCategories,
    hasProducts,
    activeAudioId,
    setActiveAudioId,
    audioUrlData,
    enableAudioFeature,
    enableReader,
    enableSpeaker,
    setEnableSpeaker,
    vendorDetails,
}) => {
    const chatEndRef = React.createRef();
    const width = isSmallScreen ? 350 : 600;
    const height = 100;

    const scrollToBottom = (chatEndRef) => {
        chatEndRef.current?.scrollIntoView({
            behavior: isGenerating ? 'smooth' : 'instant',
        });
    };
    const [viewDatabaseQuery, setViewDatabaseQuery] = useState(false);
    const [activeDbQuery, setActiveDbQuery] = useState('');
    const [enableScrollToBottom, setEnableScrollToBottom] = useState(true);

    useEffect(() => {
        scrollToBottom(chatEndRef);
    }, [chatEndRef]);

    useEffect(() => {
        setEnableScrollToBottom(true);
    }, [isGenerating]);

    let chatList = [];
    if (questionsData) {
        // Sorting the chat according to time and date
        const sortedQnAData = [...questionsData.values()].sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
        });

        chatList = sortedQnAData.map((item, index) => {
            const isLastAnswer = index === sortedQnAData.length - 1;
            return (
                <div key={index}>
                    {/* Question Container */}
                    <div className='flex w-full items-center justify-start'>
                        <div className='w-4/5 whitespace-normal break-words m-3 px-5 py-4 bg-backgrounds-primary100 max-w-[600px] rounded-xl shadow-lg'>
                            <QuestionActionView
                                user={user}
                                chatObject={item}
                                setQuery={setQuery}
                            />
                            <QuestionView chatObject={item} />
                        </div>
                    </div>

                    {/* Answer Container */}
                    <div className='flex w-full items-center justify-end'>
                        <div className='w-4/5 whitespace-normal break-words m-3 px-5 py-4 bg-backgrounds-secondaryChat max-w-[600px] rounded-xl shadow-lg'>
                            <AnswerActionView
                                index={index}
                                chatObject={item}
                                user={user}
                                setViewDatabaseQuery={setViewDatabaseQuery}
                                setActiveDbQuery={setActiveDbQuery}
                                isLastAnswer={isLastAnswer}
                                activeAudioId={activeAudioId}
                                setActiveAudioId={setActiveAudioId}
                                audioUrlData={audioUrlData}
                                enableAudioFeature={enableAudioFeature}
                                enableReader={enableReader}
                                enableSpeaker={enableSpeaker}
                                setEnableSpeaker={setEnableSpeaker}
                                setEnableScrollToBottom={
                                    setEnableScrollToBottom
                                }
                            />
                            <AnswerView
                                chatObject={item}
                                index={index}
                                activeQuestion={activeQuestion}
                                isSmallScreen={isSmallScreen}
                                isDatabase={isDatabase}
                            />

                            {/* Dummy reference div, for auto scroll to this div (bottom) when display updates */}
                            {enableScrollToBottom &&
                                index === sortedQnAData.length - 1 && (
                                    <div
                                        ref={chatEndRef}
                                        className={!isGenerating ? 'mt-2' : ''}
                                    />
                                )}
                        </div>
                    </div>
                </div>
            );
        });
    }

    return chatList && chatList.length ? (
        <div className='w-full h-full pt-4 px-4 pb-0 flex flex-col justify-between'>
            {/* Database modal */}
            {viewDatabaseQuery && (
                <Modal
                    title={'Database Query'}
                    titleIcon={faDatabase}
                    iconColor={'icons-primary'}
                    onClose={() => setViewDatabaseQuery(false)}
                >
                    <div className='w-96 text-texts-secondary300 p-3'>
                        {activeDbQuery}
                    </div>
                </Modal>
            )}

            <div className='w-full pb-2'>{chatList}</div>
            {isGenerating && (
                <div className='w-full flex items-center justify-center relative'>
                    <div className='absolute bottom-0'>
                        <Lottie
                            options={loadingOptions}
                            height={height}
                            width={width}
                        />
                    </div>

                    <div className='w-full'>
                        <GeneratingView text={'Processing, Please wait'} />
                    </div>
                </div>
            )}
        </div>
    ) : (
        <NoChatView
            isGenerating={isGenerating}
            isSmallScreen={isSmallScreen}
            selectedProductCategory={selectedProductCategory}
            selectedProduct={selectedProduct}
            hasProductCategories={hasProductCategories}
            hasProducts={hasProducts}
        />
    );
};
