import React, { useEffect, useRef } from 'react';

const AudioPlayer = ({ play, source }) => {
    // Create a ref to access the audio element
    const audioRef = useRef(null);

    // Function to play the audio
    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    // Function to pause (stop) the audio
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0; // Reset the audio to the start
        }
    };

    useEffect(() => {
        if (play) {
            playAudio();
        } else {
            stopAudio();
        }
    }, [play]);

    return <audio ref={audioRef} src={source} type='audio/mp3' />;
};

export default AudioPlayer;
