import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faFile, faGear } from '@fortawesome/free-solid-svg-icons';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { ChatActions } from './chatActions';
import { ChatDisplay } from './chatDisplay';
import { Button } from '../reusableComponents';
import AudioPlayer from '../AudioPlayer/audioPlayer';
import { toSentenceCase } from '../../utils';
import { Settings } from '../settings';

const audioSource = '/waitingMusic.mp3';

export const ChatContainer = ({
    activeQuestion,
    questionsData,
    isGenerating,
    query,
    currentQnA,
    user,
    setQuery,
    handleSubmit,
    togglePdfVisibility,
    selectedProductCategory,
    selectedProduct,
    isSmallScreen,
    enableAudioFeature,
    hasProductCategories,
    hasProducts,
    language,
    setLanguage,
    enableReader,
    setEnableReader,
    enableMusic,
    setEnableMusic,
    enableSpeaker,
    setEnableSpeaker,
    activeAudioId,
    setActiveAudioId,
    audioUrlData,
    vendorDetails,
}) => {
    const [activeAnswer, setActiveAnswer] = useState('');
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    useEffect(() => {
        if (
            activeQuestion &&
            activeQuestion.answer &&
            activeAnswer !== activeQuestion.answer
        ) {
            setActiveAnswer(activeQuestion.answer);
        }
    }, [activeQuestion]);
    return (
        <>
            {showSettingsModal && (
                <Settings
                    setShowSettingsModal={setShowSettingsModal}
                    language={language}
                    setLanguage={setLanguage}
                    enableReader={enableReader}
                    setEnableReader={setEnableReader}
                    enableMusic={enableMusic}
                    setEnableMusic={setEnableMusic}
                    vendorDetails={vendorDetails}
                    enableAudioFeature={enableAudioFeature}
                />
            )}

            <ReflexContainer orientation='horizontal'>
                <ReflexElement
                    className='header border-b shadow-sm'
                    style={{
                        minHeight: '3.5rem',
                        height: '3.5rem',
                        maxHeight: '3.5rem',
                    }}
                >
                    <div className='flex pane-content w-full h-full p-2 items-center'>
                        <div className='w-full h-full flex items-center justify-between item-center text-texts-secondary truncate px-2'>
                            <div className='w-fit'>
                                <FontAwesomeIcon
                                    icon={faComments}
                                    className='mr-2 drop-shadow text-backgrounds-primary200'
                                />
                                <span className='overflow-ellipsis overflow-hidden font-semibold w-full mr-2 text-texts-secondary300'>
                                    Chats
                                </span>
                            </div>

                            <div className='flex items-center justify-between'>
                                <div className=' ml-2 flex items-center rounded-full border'>
                                    <span
                                        className='mx-2 font-semibold text-texts-secondary300 cursor-default'
                                        title='Selected Language'
                                    >
                                        {toSentenceCase(language)}
                                    </span>
                                    <Button
                                        text={null}
                                        className={''}
                                        hoverText={'Settings'}
                                        disabled={false}
                                        onClick={() => {
                                            setShowSettingsModal(true);
                                        }}
                                        icon={faGear}
                                        type={'success'}
                                    />
                                </div>

                                {isSmallScreen && (
                                    <Button
                                        className={'ml-2'}
                                        text={null}
                                        hoverText={'View Source'}
                                        icon={faFile}
                                        onClick={(e) => {
                                            togglePdfVisibility();
                                        }}
                                        type={'success'}
                                        disabled={!activeQuestion}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement flex={1}>
                    <div className='pane-content h-full'>
                        <ChatDisplay
                            activeQuestion={activeQuestion}
                            questionsData={questionsData}
                            setQuery={setQuery}
                            currentQnA={currentQnA}
                            user={user}
                            isGenerating={isGenerating}
                            selectedProductCategory={selectedProductCategory}
                            selectedProduct={selectedProduct}
                            isSmallScreen={isSmallScreen}
                            hasProductCategories={hasProductCategories}
                            hasProducts={hasProducts}
                            activeAudioId={activeAudioId}
                            setActiveAudioId={setActiveAudioId}
                            audioUrlData={audioUrlData}
                            enableAudioFeature={enableAudioFeature}
                            enableReader={enableReader}
                            enableSpeaker={enableSpeaker}
                            setEnableSpeaker={setEnableSpeaker}
                            vendorDetails={vendorDetails}
                        />
                        <AudioPlayer
                            play={isGenerating && enableMusic}
                            source={audioSource}
                        />
                    </div>
                </ReflexElement>

                <ReflexElement
                    className='footer border-t shadow'
                    style={{
                        minHeight: '4rem',
                        height: '4rem',
                        maxHeight: '4rem',
                    }}
                >
                    <div className='pane-content w-full h-full'>
                        <div className='flex items-center justify-end w-full h-full px-2'>
                            <ChatActions
                                query={query}
                                currentQnA={currentQnA}
                                setQuery={setQuery}
                                handleSubmit={handleSubmit}
                                isGenerating={isGenerating}
                                selectedProductCategory={
                                    selectedProductCategory
                                }
                                selectedProduct={selectedProduct}
                                isSmallScreen={isSmallScreen}
                                language={language}
                                setLanguage={setLanguage}
                                activeAnswer={activeAnswer}
                                enableSpeaker={enableSpeaker}
                                setEnableSpeaker={setEnableSpeaker}
                                enableAudioFeature={enableAudioFeature}
                                setActiveAudioId={setActiveAudioId}
                            />
                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </>
    );
};
