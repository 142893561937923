// Utility function to get sentence endings based on language
export const getSentenceEndingRegex = (language) => {
    const endingsMap = {
        हिन्दी: /([।.?!])+/,
        मराठी: /([।.?!])+/,
        ENGLISH_US: /([.?!])+/,
        ENGLISH_UK: /([.?!])+/,
    };
    return endingsMap[language] || /([.?!])+/;
};
