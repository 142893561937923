import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles';
import { AuthStatus, RouterPath } from './constants';
import MainComponent from './components/mainComponent';
import CaptchaComponent from './components/captcha/captchaComponent';

import {
    NoInteractionView,
    NotificationType,
    notify,
} from './components/reusableComponents';
import VendorWrapper from './components/vendorWrapper';
import NotFoundComponent from './components/NotFoundComponent';
import { base64ToBlob, getUrl } from './utils';
import { useCallback } from 'react';
import { supportedFonts } from './fonts';

const BREAKPOINT_WIDTH = 1024;

const defaultTheme = 'default';
const defaultFont = 'segoe';

function App() {
    const [name, setName] = useState('');
    const [vendorDetails, setVendorDetails] = useState(null);
    const [authState, setAuthState] = useState(AuthStatus.NOT_AUTHENTICATED);
    const [selectedProductCategory, setSelectedProductCategory] =
        useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [
        productCategoriesAndProductsList,
        setProductCategoriesAndProductsList,
    ] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // Responsiveness related
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= BREAKPOINT_WIDTH
    );
    const [enableAudioFeature, setEnableAudioFeature] = useState(false);
    const [hasProductCategories, setHasProductCategories] = useState(false);
    const [hasProducts, setHasProducts] = useState(false);
    const [theme, setTheme] = useState(defaultTheme);
    const [font, setFont] = useState(defaultFont); // Default font

    useEffect(() => {
        if (vendorDetails) {
            if (vendorDetails.theme && theme !== vendorDetails.theme) {
                setTheme(vendorDetails.theme);
            }

            if (vendorDetails.font && font !== vendorDetails.font) {
                setFont(vendorDetails.font);
            }
        }
    }, [vendorDetails]);

    useEffect(() => {
        document.documentElement.className = `theme-${theme} ${supportedFonts[font].class}`;
    }, [theme, font]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= BREAKPOINT_WIDTH);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (productCategoriesAndProductsList.length >= 1) {
            setHasProductCategories(true);
            setHasProducts(true);
        }
    }, [productCategoriesAndProductsList]);

    const verifyAuthentication = useCallback(
        (vendorId) => {
            if (!vendorId) {
                if (authState !== AuthStatus.NOT_AUTHENTICATED) {
                    setAuthState(AuthStatus.NOT_AUTHENTICATED);
                }
                if (vendorDetails) {
                    setVendorDetails(null);
                }
                return false;
            }

            // Get productSupport data from localStorage
            const productSupport = JSON.parse(
                localStorage.getItem('productSupport')
            );

            if (!productSupport) {
                if (authState !== AuthStatus.NOT_AUTHENTICATED) {
                    setAuthState(AuthStatus.NOT_AUTHENTICATED);
                }
                return false;
            }

            const foundVendor = productSupport[vendorId];
            if (!foundVendor) {
                if (vendorDetails) {
                    productSupport[vendorId] = {
                        ...vendorDetails,
                        userName: name,
                    };

                    localStorage.setItem(
                        'productSupport',
                        JSON.stringify(productSupport)
                    );
                }
                if (authState !== AuthStatus.NOT_AUTHENTICATED) {
                    setAuthState(AuthStatus.NOT_AUTHENTICATED);
                }
                return false;
            }

            const jwtToken = foundVendor.token;
            if (!jwtToken) {
                if (authState !== AuthStatus.NOT_AUTHENTICATED) {
                    setAuthState(AuthStatus.NOT_AUTHENTICATED);
                }
                return false;
            }

            if (!vendorDetails) {
                const details = { ...foundVendor };
                delete details.token;
                setVendorDetails(details);
            }

            if (authState !== AuthStatus.AUTHENTICATED) {
                setAuthState(AuthStatus.AUTHENTICATED);
            }

            return true;
        },
        [authState, name, vendorDetails]
    );

    const fetchVendorDetails = useCallback(async (vendorId, navigate, path) => {
        try {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('token', vendorId);

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/get-vendor-details`
            );

            // Send the FormData
            const response = await axios.post(dynamicUrl, formData, {});

            const vendorDetails = response.data;

            if (Object.keys(vendorDetails).length === 0) {
                setIsLoading(false);
                return false;
            }

            const {
                logo,
                company_name,
                contact_email,
                contact_number,
                company_domain,
                vendor_id,
                copy_right_text,
                theme,
                font,
                language,
                allow_language_selection,
                allow_audio_feature,
            } = vendorDetails;

            const modifiedVendorDetails = {
                id: vendor_id,
                name: company_name,
                logo: logo ? base64ToBlob(logo, 'image/png') : null,
                number: contact_number,
                email: contact_email,
                domain: company_domain,
                copyRightText: copy_right_text,
                theme: theme,
                font: font,
                language: language,
                allowLanguageSelection: allow_language_selection,
                allowAudioFeature: allow_audio_feature,
            };

            setVendorDetails(modifiedVendorDetails);

            // Saving data to local storage
            let productSupportUserData = JSON.parse(
                localStorage.getItem('productSupport')
            );

            if (!productSupportUserData) {
                productSupportUserData = {};
            }

            if (productSupportUserData[vendorId]) {
                productSupportUserData[vendorId] = {
                    ...productSupportUserData[vendorId],
                    ...modifiedVendorDetails,
                };
            } else {
                productSupportUserData[vendorId] = {
                    ...modifiedVendorDetails,
                };
            }

            localStorage.setItem(
                'productSupport',
                JSON.stringify(productSupportUserData)
            );

            setIsLoading(false);
            return true;
        } catch (error) {
            notify('Error fetching vendor details', NotificationType.ERROR);
            setIsLoading(false);
            return false;
        }
    }, []);

    if (isLoading) {
        return <NoInteractionView viewText={"Loading Vendor's Details"} />;
    }
    return (
        <div className={`${supportedFonts[font].class}`}>
            <ToastContainer newestOnTop={false} rtl={false} pauseOnFocusLoss />
            <BrowserRouter>
                <Routes>
                    {authState === AuthStatus.AUTHENTICATED ? (
                        <>
                            {/* Non-Authenticated routes */}
                            <Route
                                path={`/${RouterPath.CHAT_SUPPORT}/:vendorId`}
                                element={
                                    <MainComponent
                                        vendorDetails={vendorDetails}
                                        selectedProductCategory={
                                            selectedProductCategory
                                        }
                                        selectedProduct={selectedProduct}
                                        setSelectedProductCategory={
                                            setSelectedProductCategory
                                        }
                                        setSelectedProduct={setSelectedProduct}
                                        productCategoriesAndProductsList={
                                            productCategoriesAndProductsList
                                        }
                                        setProductCategoriesAndProductsList={
                                            setProductCategoriesAndProductsList
                                        }
                                        verifyAuthentication={
                                            verifyAuthentication
                                        }
                                        fetchVendorDetails={fetchVendorDetails}
                                        isSmallScreen={isSmallScreen}
                                        setAuthState={setAuthState}
                                        enableAudioFeature={enableAudioFeature}
                                        setEnableAudioFeature={
                                            setEnableAudioFeature
                                        }
                                        hasProductCategories={
                                            hasProductCategories
                                        }
                                        hasProducts={hasProducts}
                                    />
                                }
                            />
                            <Route
                                path={`/${RouterPath.VERIFY_CAPTCHA}/:vendorId`}
                                element={
                                    <VendorWrapper
                                        authState={authState}
                                        vendorDetails={vendorDetails}
                                        verifyAuthentication={
                                            verifyAuthentication
                                        }
                                        fetchVendorDetails={fetchVendorDetails}
                                        enableAudioFeature={enableAudioFeature}
                                        setEnableAudioFeature={
                                            setEnableAudioFeature
                                        }
                                    />
                                }
                            />

                            <Route
                                path={`/${RouterPath.VENDOR}/:vendorId`}
                                element={
                                    <VendorWrapper
                                        authState={authState}
                                        vendorDetails={vendorDetails}
                                        verifyAuthentication={
                                            verifyAuthentication
                                        }
                                        fetchVendorDetails={fetchVendorDetails}
                                        enableAudioFeature={enableAudioFeature}
                                        setEnableAudioFeature={
                                            setEnableAudioFeature
                                        }
                                    />
                                }
                            />

                            <Route
                                path={`/${RouterPath.NOT_FOUND}`}
                                element={<NotFoundComponent />}
                            />

                            <Route
                                path='*'
                                element={
                                    <Navigate to={`/${RouterPath.NOT_FOUND}`} />
                                }
                            />
                        </>
                    ) : (
                        <>
                            {/* Non-Authenticated routes */}

                            <Route
                                path={`/${RouterPath.VERIFY_CAPTCHA}/:vendorId`}
                                element={
                                    <CaptchaComponent
                                        name={name}
                                        setName={setName}
                                        setAuthState={setAuthState}
                                        vendorDetails={vendorDetails}
                                        verifyAuthentication={
                                            verifyAuthentication
                                        }
                                        fetchVendorDetails={fetchVendorDetails}
                                        isSmallScreen={isSmallScreen}
                                    />
                                }
                            />

                            <Route
                                path={`/${RouterPath.CHAT_SUPPORT}/:vendorId`}
                                element={
                                    <VendorWrapper
                                        authState={authState}
                                        vendorDetails={vendorDetails}
                                        verifyAuthentication={
                                            verifyAuthentication
                                        }
                                        fetchVendorDetails={fetchVendorDetails}
                                        enableAudioFeature={enableAudioFeature}
                                        setEnableAudioFeature={
                                            setEnableAudioFeature
                                        }
                                    />
                                }
                            />

                            <Route
                                path={`/${RouterPath.VENDOR}/:vendorId`}
                                element={
                                    <VendorWrapper
                                        authState={authState}
                                        vendorDetails={vendorDetails}
                                        verifyAuthentication={
                                            verifyAuthentication
                                        }
                                        fetchVendorDetails={fetchVendorDetails}
                                        enableAudioFeature={enableAudioFeature}
                                        setEnableAudioFeature={
                                            setEnableAudioFeature
                                        }
                                    />
                                }
                            />

                            <Route
                                path={`/${RouterPath.NOT_FOUND}`}
                                element={<NotFoundComponent />}
                            />

                            <Route
                                path='*'
                                element={
                                    <Navigate to={`/${RouterPath.NOT_FOUND}`} />
                                }
                            />
                        </>
                    )}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
