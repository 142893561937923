import React, { useRef, useState } from 'react';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { Button, Input } from '../reusableComponents';
import { RealTimeTranscription } from '../transcriptions';

export const ChatActions = ({
    query,
    setQuery,
    handleSubmit,
    isGenerating,
    selectedProductCategory,
    selectedProduct,
    isSmallScreen,
    language,
    setLanguage,
    enableSpeaker,
    setEnableSpeaker,
    activeAnswer,
    enableAudioFeature,
    setActiveAudioId,
}) => {
    const [isListening, setIsListening] = useState(false);
    const buttonRef = useRef(null);

    const handleOnTranscription = (transcript) => {
        if (transcript) {
            setQuery(transcript);
        }
    };

    const handleTranscriptionReset = () => {
        setQuery('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            buttonRef?.current?.click(); // Programmatically click the button
            setIsListening(false);
        }
    };

    return (
        <div className='w-full'>
            <div className='flex items-center justify-between'>
                <div className='w-full bg-backgrounds-white border border-1 rounded-xl'>
                    <Input
                        className='px-4 h-fit w-full'
                        value={query}
                        type='text'
                        placeholder={
                            selectedProductCategory && selectedProduct
                                ? `Ask question here for ${selectedProduct} (${selectedProductCategory})`
                                : `Ask question here after selecting a product`
                        }
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                    />
                </div>

                <div className='flex items-center justify-between ml-2'>
                    {enableAudioFeature && (
                        <div
                            className={`ml-1 mr-2 ${
                                isSmallScreen ? 'relative right-0 z-10' : ''
                            }`}
                        >
                            <RealTimeTranscription
                                language={language}
                                setLanguage={setLanguage}
                                onTranscription={handleOnTranscription}
                                onTranscriptionReset={handleTranscriptionReset}
                                isListening={isListening}
                                setIsListening={setIsListening}
                                activeAnswer={activeAnswer}
                                enableSpeaker={enableSpeaker}
                                setEnableSpeaker={setEnableSpeaker}
                                setActiveAudioId={setActiveAudioId}
                                isSmallScreen={isSmallScreen}
                            />
                        </div>
                    )}

                    <Button
                        ref={buttonRef}
                        text={!isSmallScreen ? 'Send' : null}
                        className={`mx-1`}
                        hoverText='Send question and get single answer'
                        disabled={query.length === 0 || isGenerating}
                        onClick={async () => {
                            setIsListening(false);
                            await handleSubmit(language);
                            setQuery('');
                        }}
                        icon={faPaperPlane}
                        type={'success'}
                    />
                </div>
            </div>
        </div>
    );
};
