import React, { useEffect, useState } from 'react';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import axios from 'axios';
import { PdfContainer } from './pdfDisplay';
import { ChatContainer } from './chatSection';
import { ErrorType } from '../constants/errorType';
import { useNavigate, useParams } from 'react-router';
import { getUrl, splitTextIntoChunks } from '../utils';
import { useCallback } from 'react';
import { AuthStatus, RouterPath } from '../constants';
import { NotificationType, notify } from './reusableComponents';
import { supportedLanguages } from '../languages';

const MAX_CHUNK_LENGTH = 700;

const logout = (
    vendorId,
    setIsGenerating,
    setAuthState,
    navigate,
    enableAudioFeature
) => {
    notify('Login timeout! Please login again.', NotificationType.INFO);
    setIsGenerating(false);

    // Clear session ID on logout
    sessionStorage.removeItem('sessionId');

    // Get productSupport data from localStorage
    const productSupport = JSON.parse(localStorage.getItem('productSupport'));
    const foundVendor = productSupport[vendorId];
    if (foundVendor) {
        productSupport[vendorId] = {
            ...foundVendor,
            token: null,
        };

        localStorage.setItem('productSupport', JSON.stringify(productSupport));
    }

    setAuthState(AuthStatus.NOT_AUTHENTICATED);

    const path = `/${RouterPath.VERIFY_CAPTCHA}/${vendorId}${
        enableAudioFeature ? '?audio=true' : ''
    }`;
    navigate(path);
};

const fetchTTSResponse = async (text, language) => {
    try {
        const lang = supportedLanguages[language];
        const ttsUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/tts`
        );

        const response = await axios.get(ttsUrl, {
            params: { text, language: lang },
            responseType: 'blob', // Important for binary audio response
        });

        if (response) {
            const ttsBlob = new Blob([response.data], { type: 'audio/mpeg' });
            const audioURL = URL.createObjectURL(ttsBlob);

            return audioURL;
        }
    } catch (error) {
        console.error('TTS API Error:', error);
        throw error;
    }
};

// Splits text, calls TTS API in parallel for chunks, and updates state
export const processTextToAudioChunks = async (text, language) => {
    if (!text.trim()) return;

    if (text.length <= MAX_CHUNK_LENGTH) {
        try {
            const audioURL = await fetchTTSResponse(text, language);
            return [audioURL];
        } catch (error) {
            console.error('Failed to fetch single chunk TTS:', error);
        }
        return;
    }

    const chunks = splitTextIntoChunks(text, language, MAX_CHUNK_LENGTH);

    try {
        // Call all APIs in parallel for faster processing
        const audioUrls = await Promise.allSettled(
            chunks.map((chunk) => fetchTTSResponse(chunk, language))
        );
        const successfulUrls = audioUrls
            .filter((result) => result.status === 'fulfilled')
            .map((result) => result.value);

        if (successfulUrls.length > 0) {
            return successfulUrls;
        } else {
            console.error('All TTS API calls failed!');
        }
    } catch (error) {
        console.error('Error processing audio chunks:', error);
    }
};

const ChatAndPdfContainer = (props) => {
    const { vendorId } = useParams(); // Extract the vendorId from the URL path
    const navigate = useNavigate();

    const {
        selectedProductCategory,
        selectedProduct,
        isSmallScreen,
        enableAudioFeature,
        hasProductCategories,
        hasProducts,
        language,
        setLanguage,
        enableReader,
        setEnableReader,
        enableMusic,
        setEnableMusic,
        enableSpeaker,
        setEnableSpeaker,
        activeAudioId,
        setActiveAudioId,
        audioUrlData,
        setAudioUrlData,
        vendorDetails,
    } = props;

    const [query, setQuery] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [currentqna, setcurrentqna] = useState([]);

    const [questionsData, setQuestionsData] = useState(new Map());
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [abortController, setAbortController] = useState(null);

    const handleSubmit = useCallback(
        async (language) => {
            try {
                const isAuthenticated = props.verifyAuthentication(vendorId);
                if (!isAuthenticated) {
                    return;
                }

                if (query === '') {
                    notify('Question cannot be empty', NotificationType.INFO);
                    return;
                }

                //e.preventDefault();
                setIsGenerating(true);

                // Create a new AbortController
                const controller = new AbortController();
                setAbortController(controller); // Save it in state

                const productCategory = selectedProductCategory;
                const product = selectedProduct;
                const sessionId = sessionStorage.getItem('sessionId');
                const dynamicUrl = getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/run-customer-qna`
                );

                const response = await axios.post(
                    dynamicUrl,
                    {
                        token: props.user.token,
                        query: query,
                        productCategory: productCategory,
                        product: product,
                        language: language,
                        enabled: true,
                        sessionId: String(sessionId),
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        signal: controller.signal, // Pass the signal
                    }
                );

                const data = response.data;

                if (data) {
                    const questionData = {
                        qid: data.qid,
                        question: data.question,
                        answer: data.answer,
                        timestamp: new Date().toLocaleString(),
                        fileToChat: data.metadata[0].pdf_name,
                        chunksData: [],
                        queries: [],
                        productCategory: data.productCategory,
                        product: data.product,
                    };

                    data.chunksData.forEach((chunk) => {
                        const chunkData = {
                            folderName: chunk.metadata.foldername,
                            fileName: chunk.metadata.source,
                            actualFileName: chunk.metadata.source,
                            pageNumber: chunk.metadata.page_number,
                            pageContent: chunk.page_content,
                        };

                        questionData.chunksData.push(chunkData);
                    });

                    questionsData.set(data.qid, questionData);

                    setQuestionsData(questionsData);
                    setActiveQuestion(questionData);

                    const qna = {
                        question: data.question,
                        answer: data.answer,
                        qid: data.qid,
                        productCategory: data.productCategory,
                        product: data.product,
                    };
                    setcurrentqna([qna]);

                    setQuery('');

                    // Call TTS API for speech output
                    const audioURLArray = await processTextToAudioChunks(
                        data.answer,
                        language
                    );

                    const updatedAudioUrlData = { ...audioUrlData };
                    updatedAudioUrlData[data.qid] = audioURLArray;
                    setAudioUrlData(updatedAudioUrlData);

                    setActiveAudioId(data.qid);
                } else if (data.error || data.detail) {
                    notify(
                        `Error: ${data.error || data.detail}`,
                        NotificationType.ERROR
                    );
                }
            } catch (error) {
                if (error.name === ErrorType.ABORT_ERROR) {
                    notify(
                        'Request changed due to page change while processing',
                        NotificationType.INFO
                    );
                } else {
                    console.log('Error:', error);
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error &&
                        error.response.data.error === 'invalid token'
                    ) {
                        logout(
                            vendorId,
                            setIsGenerating,
                            props.setAuthState,
                            navigate,
                            enableAudioFeature
                        );
                    } else {
                        notify(
                            'Something went wrong while generating answer',
                            NotificationType.ERROR
                        );
                    }
                }
            } finally {
                setIsGenerating(false);
            }
        },
        [
            currentqna,
            props,
            query,
            questionsData,
            selectedProduct,
            selectedProductCategory,
            vendorId,
        ]
    );

    // Effect to handle repository change
    useEffect(() => {
        if (abortController) {
            // Abort the previous request
            abortController.abort();
        }
        // Clear the controller after aborting
        setAbortController(null);
    }, []); // Run this effect whenever repo changes

    useEffect(() => {
        const handleBeforeUnload = () => {
            // Reset togglePdfVisibility here
            props.togglePdfVisibility();
        };

        // Add event listener for beforeunload event
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array to ensure this effect runs only once on mount

    useEffect(() => {
        // Reset both states to false when the component mounts or updates
        props.setShowPdf(false);
        props.setOnButton(false);
    }, []);

    return (
        <div className='h-full w-full'>
            <>
                <div className='flex items-center justify-center px-0 z-0 h-[89vh]'>
                    <div className='w-full h-full'>
                        {activeQuestion ? (
                            <ReflexContainer orientation='vertical'>
                                {!props.showPdf && (
                                    <ReflexElement className='left-pane'>
                                        <div className='pane-content pr-0.5 w-full h-full bg-transparent'>
                                            <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                                <ChatContainer
                                                    activeQuestion={
                                                        activeQuestion
                                                    }
                                                    isGenerating={isGenerating}
                                                    setIsGenerating={
                                                        setIsGenerating
                                                    }
                                                    questionsData={
                                                        questionsData
                                                    }
                                                    query={query}
                                                    currentQnA={currentqna}
                                                    user={props.user}
                                                    setQuery={setQuery}
                                                    handleSubmit={handleSubmit}
                                                    togglePdfVisibility={
                                                        props.togglePdfVisibility
                                                    }
                                                    selectedProductCategory={
                                                        selectedProductCategory
                                                    }
                                                    selectedProduct={
                                                        selectedProduct
                                                    }
                                                    isSmallScreen={
                                                        isSmallScreen
                                                    }
                                                    enableAudioFeature={
                                                        enableAudioFeature
                                                    }
                                                    hasProductCategories={
                                                        hasProductCategories
                                                    }
                                                    hasProducts={hasProducts}
                                                    language={language}
                                                    setLanguage={setLanguage}
                                                    enableReader={enableReader}
                                                    setEnableReader={
                                                        setEnableReader
                                                    }
                                                    enableMusic={enableMusic}
                                                    setEnableMusic={
                                                        setEnableMusic
                                                    }
                                                    enableSpeaker={
                                                        enableSpeaker
                                                    }
                                                    setEnableSpeaker={
                                                        setEnableSpeaker
                                                    }
                                                    activeAudioId={
                                                        activeAudioId
                                                    }
                                                    setActiveAudioId={
                                                        setActiveAudioId
                                                    }
                                                    audioUrlData={audioUrlData}
                                                    vendorDetails={
                                                        vendorDetails
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </ReflexElement>
                                )}

                                {!isSmallScreen && (
                                    <ReflexSplitter
                                        style={{
                                            border: 0,
                                            background: 'transparent',
                                            width: '6px',
                                            zIndex: 0,
                                        }}
                                    />
                                )}

                                {/* Render PDF view based on showPdf */}
                                {(props.showPdf ||
                                    props.onButton ||
                                    !isSmallScreen) && (
                                    <ReflexElement className='right-pane'>
                                        <div className='pane-content pl-0.5 w-full h-full bg-transparent'>
                                            <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                                <PdfContainer
                                                    user={props.user}
                                                    activeQuestion={
                                                        activeQuestion
                                                    }
                                                    togglePdfVisibility={
                                                        props.togglePdfVisibility
                                                    }
                                                    isSmallScreen={
                                                        isSmallScreen
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </ReflexElement>
                                )}
                            </ReflexContainer>
                        ) : (
                            <ReflexContainer orientation='vertical'>
                                <ReflexElement className='middle-pane'>
                                    <div className='pane-content w-full h-full bg-transparent'>
                                        <div className='w-full h-full border border-1 overflow-auto bg-backgrounds-secondary rounded-xl'>
                                            <ChatContainer
                                                activeQuestion={activeQuestion}
                                                isGenerating={isGenerating}
                                                setIsGenerating={
                                                    setIsGenerating
                                                }
                                                questionsData={questionsData}
                                                query={query}
                                                currentQnA={currentqna}
                                                user={props.user}
                                                setQuery={setQuery}
                                                handleSubmit={handleSubmit}
                                                togglePdfVisibility={
                                                    props.togglePdfVisibility
                                                }
                                                selectedProductCategory={
                                                    selectedProductCategory
                                                }
                                                selectedProduct={
                                                    selectedProduct
                                                }
                                                isSmallScreen={isSmallScreen}
                                                enableAudioFeature={
                                                    enableAudioFeature
                                                }
                                                hasProductCategories={
                                                    hasProductCategories
                                                }
                                                hasProducts={hasProducts}
                                                language={language}
                                                setLanguage={setLanguage}
                                                enableReader={enableReader}
                                                setEnableReader={
                                                    setEnableReader
                                                }
                                                enableMusic={enableMusic}
                                                setEnableMusic={setEnableMusic}
                                                enableSpeaker={enableSpeaker}
                                                setEnableSpeaker={
                                                    setEnableSpeaker
                                                }
                                                activeAudioId={activeAudioId}
                                                setActiveAudioId={
                                                    setActiveAudioId
                                                }
                                                audioUrlData={audioUrlData}
                                                vendorDetails={vendorDetails}
                                            />
                                        </div>
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};

export default ChatAndPdfContainer;
