import React, { useState, useEffect } from 'react';
import Navbar from './navbar';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { DataType, RouterPath } from '../constants';
import ChatAndPdfContainer from './chatAndPdfContainer';
import { useNavigate, useParams } from 'react-router';
import { supportedLanguages } from '../languages';
import {
    NoInteractionView,
    NotificationType,
    notify,
} from './reusableComponents';
import axios from 'axios';
import { getUrl } from '../utils';
import { useSearchParams } from 'react-router-dom';
import { defaultLanguage } from '../languages';

const generateSessionId = () => {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
        sessionId = crypto.randomUUID(); // Generate unique session ID
        sessionStorage.setItem('sessionId', sessionId);
    }
};

const fetchProductCategoriesAndProducts = async (
    user,
    setProductCategoriesAndProductsList
) => {
    try {
        if (user) {
            const token = user.token;
            const formData = new FormData();
            formData.append('token', token);

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/get-vendor-product-and-categories`
            );

            // Send the FormData
            const response = await axios.post(dynamicUrl, formData, {});
            // Generate and store session ID after the API call

            generateSessionId();

            const data = response.data.userProductCategoriesAndProducts;
            if (data) {
                setProductCategoriesAndProductsList(data);
            } else {
                setProductCategoriesAndProductsList([]);
            }
        }
    } catch (error) {
        notify(
            'Error fetching product categories and products',
            NotificationType.ERROR
        );
    }
};
// Reverse mapping'
const languageMap = Object.fromEntries(
    Object.entries(supportedLanguages).map(([key, value]) => [value, key])
);
const MainComponent = ({
    selectedProductCategory,
    setSelectedProductCategory,
    selectedProduct,
    setSelectedProduct,
    productCategoriesAndProductsList,
    setProductCategoriesAndProductsList,
    vendorDetails,
    fetchVendorDetails,
    verifyAuthentication,
    isSmallScreen,
    setAuthState,
    enableAudioFeature,
    setEnableAudioFeature,
    hasProductCategories,
    hasProducts,
}) => {
    const [searchParams] = useSearchParams();
    const hasAudioParams = searchParams.get('audio') === 'true'; // Check if audio parameter is true

    const [isLoading, setIsLoading] = useState(false);

    //responsive mode functions and variables
    const [showPdf, setShowPdf] = useState(false);
    const [onButton, setOnButton] = useState(false);

    // Language, voice and music related settings
    const [language, setLanguage] = useState(
        languageMap[vendorDetails?.language] ?? defaultLanguage
    );

    const [enableReader, setEnableReader] = useState(true);
    const [enableMusic, setEnableMusic] = useState(true);

    const [enableSpeaker, setEnableSpeaker] = useState(false);
    const [activeAudioId, setActiveAudioId] = useState(null);
    const [audioUrlData, setAudioUrlData] = useState({});

    const { vendorId } = useParams(); // Extract the vendorId from the URL path
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('productSupport'))[vendorId];

    useEffect(() => {
        if (hasAudioParams && !enableAudioFeature) {
            setEnableAudioFeature(true);
        }
    }, []);

    useEffect(() => {
        if (user.token) {
            setIsLoading(true);
            (async () => {
                await fetchProductCategoriesAndProducts(
                    user,
                    setProductCategoriesAndProductsList
                );
            })();
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!vendorId) {
            navigate(`/${RouterPath.NOT_FOUND}`);
            return;
        }
        if (!vendorDetails) {
            (async () => {
                const isFetched = await fetchVendorDetails(vendorId);
                if (!isFetched) {
                    navigate(`/${RouterPath.NOT_FOUND}`);
                }
            })();
        }
    }, [vendorDetails, vendorId]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            localStorage.setItem('isRefresh', 'true'); // Flag to indicate that the page was loaded

            // Retrieve productSupport and temp data from localStorage
            // let tempData = JSON.parse(localStorage.getItem('tempData'));
            let productSupport = JSON.parse(
                localStorage.getItem('productSupport')
            );
            // const vendorData = productSupport[vendorId];

            // Check if productSupport exists and vendorId exists in the object
            // if (!tempData) {
            //     tempData = {};
            // }

            // tempData[vendorId] = vendorData;
            // localStorage.setItem('tempData', JSON.stringify(tempData));

            delete productSupport[vendorId];
            // Update the localStorage with the modified productSupport
            localStorage.setItem(
                'productSupport',
                JSON.stringify(productSupport)
            );
        };

        // const handleLoad = () => {
        //     // Check if the 'isRefresh' flag exists in localStorage
        //     const isRefresh = localStorage.getItem('isRefresh');

        //     // If the flag is present, it indicates a refresh; otherwise, it's a tab close
        //     if (isRefresh) {
        //         // For refresh, just clear the flag (so it can persist on refresh)
        //         localStorage.removeItem('isRefresh');

        //         // Retrieve productSupport data from localStorage
        //         let tempData = JSON.parse(localStorage.getItem('tempData'));
        //         // Retrieve productSupport data from localStorage
        //         let productSupport = JSON.parse(
        //             localStorage.getItem('productSupport')
        //         );
        //         const vendorData = tempData[vendorId];

        //         // Check if productSupport exists and vendorId exists in the object
        //         if (vendorData) {
        //             if (!productSupport) {
        //                 productSupport = {};
        //             }
        //             productSupport[vendorId] = vendorData;
        //             // Update the localStorage with the modified productSupport
        //             localStorage.setItem(
        //                 'productSupport',
        //                 JSON.stringify(productSupport)
        //             );

        //             delete tempData[vendorId];
        //             // Update the localStorage with the modified tempData
        //             localStorage.setItem(
        //                 'tempData',
        //                 JSON.stringify(tempData)
        //             );
        //         }
        //     }
        // };

        // Set a flag when the page is loaded
        // window.addEventListener('load', handleLoad);
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Cleanup event listeners on component unmount
            window.removeEventListener('beforeunload', handleBeforeUnload);
            // window.removeEventListener('load', handleLoad);
        };
    }, [vendorId]); // Re-run effect if vendorId changes

    useEffect(() => {
        // Reset both states to false when the component mounts or updates
        setShowPdf(false);
        setOnButton(false);
    }, [isSmallScreen]);

    const togglePdfVisibility = (reset) => {
        if (typeof reset === DataType.STRING) {
            setShowPdf(false);
            setOnButton(false);
        } else {
            setShowPdf((prevState) => !prevState);
            setOnButton((prevState) => !prevState);
        }
    };

    if (isLoading) {
        return <NoInteractionView viewText={'Loading Products Details'} />;
    }

    return (
        <div className='z-0 w-full h-full m-auto my-2'>
            <ReflexContainer orientation='horizontal'>
                <ReflexElement
                    className='header'
                    style={{
                        minHeight: '4rem',
                        height: '4rem',
                        maxHeight: '4rem',
                    }}
                >
                    <div className='pane-content w-full h-full px-2'>
                        <Navbar
                            vendorDetails={vendorDetails}
                            productDetailsList={
                                productCategoriesAndProductsList
                            }
                            selectedProductCategory={selectedProductCategory}
                            selectedProduct={selectedProduct}
                            setSelectedProductCategory={
                                setSelectedProductCategory
                            }
                            setSelectedProduct={setSelectedProduct}
                            isLoading={isLoading}
                            isSmallScreen={isSmallScreen}
                        />
                    </div>
                </ReflexElement>

                <ReflexElement flex={1}>
                    <div className='pane-content h-full w-full px-2'>
                        <ChatAndPdfContainer
                            user={user}
                            showPdf={showPdf}
                            onButton={onButton}
                            setShowPdf={setShowPdf}
                            setOnButton={setOnButton}
                            togglePdfVisibility={togglePdfVisibility}
                            selectedProductCategory={selectedProductCategory}
                            selectedProduct={selectedProduct}
                            isSmallScreen={isSmallScreen}
                            verifyAuthentication={verifyAuthentication}
                            setAuthState={setAuthState}
                            enableAudioFeature={enableAudioFeature}
                            hasProductCategories={hasProductCategories}
                            hasProducts={hasProducts}
                            language={language}
                            setLanguage={setLanguage}
                            enableReader={enableReader}
                            setEnableReader={setEnableReader}
                            enableMusic={enableMusic}
                            setEnableMusic={setEnableMusic}
                            enableSpeaker={enableSpeaker}
                            setEnableSpeaker={setEnableSpeaker}
                            activeAudioId={activeAudioId}
                            setActiveAudioId={setActiveAudioId}
                            audioUrlData={audioUrlData}
                            setAudioUrlData={setAudioUrlData}
                            vendorDetails={vendorDetails}
                        />
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};

export default MainComponent;
