import React, { useEffect, useRef, forwardRef } from 'react';

export const Input = forwardRef(
    (
        {
            value,
            placeholder,
            pattern,
            type = 'text',
            onChange,
            className,
            disabled,
            onInvalid,
            onInput,
            maxLength,
            hoverText,
            autoFocus,
            onKeyDown,
        },
        ref
    ) => {
        const inputRef = useRef(null);

        useEffect(() => {
            if (autoFocus && inputRef.current) {
                inputRef.current.focus();
            }
        }, [autoFocus, value]);

        useEffect(() => {
            if (inputRef.current) {
                // Scroll input to show last word
                inputRef.current.scrollLeft = inputRef.current.scrollWidth;
            }
        }, [value, type]);

        return (
            <>
                <style>
                    {`input[type="password"]::-ms-reveal {
                        display: none;
                    }`}
                </style>
                <input
                    ref={ref || inputRef} // Use forwarded ref if provided
                    className={`${
                        !disabled
                            ? 'bg-backgrounds-white text-texts-secondary'
                            : 'bg-backgrounds-slate text-texts-secondary300'
                    } w-full p-2.5 rounded-xl hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300 ${
                        className ? className : ''
                    }`}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onInvalid={onInvalid}
                    onInput={onInput}
                    pattern={pattern}
                    onChange={onChange}
                    disabled={disabled}
                    maxLength={maxLength} // Use correct camelCase prop
                    title={hoverText}
                    onKeyDown={onKeyDown}
                />
            </>
        );
    }
);
