import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AuthStatus, RouterPath } from '../constants';

const VendorWrapper = ({
    authState,
    vendorDetails,
    fetchVendorDetails,
    verifyAuthentication,
    enableAudioFeature,
    setEnableAudioFeature,
}) => {
    const { vendorId } = useParams(); // Extract the vendorId from the URL path
    const [searchParams] = useSearchParams(); // Extract query parameters
    const navigate = useNavigate();

    useEffect(() => {
        verifyAuthentication(vendorId);

        return () => {};
    }, [vendorId]);

    useEffect(() => {
        // Extract the 'voice' query parameter
        const voiceParam = searchParams.get('audio');
        if (voiceParam === 'true' && !enableAudioFeature) {
            setEnableAudioFeature(true);
        } else {
            if (enableAudioFeature) {
                setEnableAudioFeature(false);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (!vendorId) {
            navigate(`/${RouterPath.NOT_FOUND}`);
            return;
        }

        const hasAudioParams = searchParams.get('audio') === 'true';
        const path =
            authState === AuthStatus.AUTHENTICATED
                ? `/${RouterPath.CHAT_SUPPORT}/${vendorId}${
                      hasAudioParams ? '?audio=true' : ''
                  }`
                : `/${RouterPath.VERIFY_CAPTCHA}/${vendorId}${
                      hasAudioParams ? '?audio=true' : ''
                  }`;

        if (!vendorDetails) {
            (async () => {
                const isFetched = await fetchVendorDetails(vendorId);
                if (isFetched) {
                    navigate(path);
                } else {
                    navigate(`/${RouterPath.NOT_FOUND}`);
                }
            })();
            return;
        }

        navigate(path);
    }, [authState, vendorDetails, vendorId]);

    // This component doesn't need to render anything, it just fetches the vendor details
    return null;
};

export default VendorWrapper;
