import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from '../reusableComponents';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { toSentenceCase } from '../../utils';
import Toggle from '../reusableComponents/Toggle';
import { supportedLanguages } from '../../languages';

export const Settings = ({
    setShowSettingsModal,
    language,
    setLanguage,
    enableReader,
    setEnableReader,
    enableMusic,
    setEnableMusic,
    vendorDetails,
    enableAudioFeature,
}) => {
    const [languageDropdown, setLanguageDropdown] = useState([]);
    const supportedLang = Object.keys(supportedLanguages);

    useEffect(() => {
        const languageDropDown = supportedLang.map((lang) => {
            const data = {
                key: lang,
                label: toSentenceCase(lang),
                icon: null,
                iconColor: null,
                selected: lang === language,
                action: () => {
                    setLanguage(lang);
                },
            };
            return data;
        });

        setLanguageDropdown(languageDropDown);
    }, [language]);
    return (
        <Modal
            title={'Settings'}
            titleIcon={faGear}
            iconColor={'sky'}
            onClose={() => setShowSettingsModal(false)}
            className={''}
        >
            <div className='w-80 h-fit flex flex-col items-start justify-center p-4'>
                <div
                    className='w-full flex items-center'
                    title='Voice Language'
                >
                    <label className='w-1/3 mr-2 font-semibold text-texts-secondary300'>
                        Language
                    </label>
                    <div className='w-2/3'>
                        <Dropdown
                            dropDownList={languageDropdown}
                            showButtonText={true}
                            buttonText={toSentenceCase(language)}
                            titleText={null}
                            buttonHoverText={'Select Speech Language'}
                            buttonClassName={
                                'text-texts-tertiary400 mr-1 h-8 w-40'
                            }
                            listClassName={''}
                            listTitleText={'Supported Languages'}
                            disabled={!vendorDetails?.allowLanguageSelection}
                            placement={'bottom'}
                        />
                    </div>
                </div>

                {enableAudioFeature && (
                    <>
                        <br />

                        <div
                            className='w-full flex items-center'
                            title={`Read aloud new response: ${
                                enableReader ? 'Enabled' : 'Disabled'
                            }`}
                        >
                            <Toggle
                                toggleText={'Reader'}
                                onChange={() => setEnableReader(!enableReader)}
                                checked={enableReader && enableAudioFeature}
                                toggleTextClassName={
                                    'w-1/3 mr-2 ml-0.5 font-semibold text-texts-secondary300'
                                }
                            />
                        </div>
                    </>
                )}

                <br />

                <div
                    className='w-full flex items-center'
                    title={`Waiting Music: ${
                        enableMusic ? 'Enabled' : 'Disabled'
                    }`}
                >
                    <Toggle
                        toggleText={'Music'}
                        onChange={() => setEnableMusic(!enableMusic)}
                        checked={enableMusic}
                        toggleTextClassName={
                            'w-1/3 mr-2 ml-0.5 font-semibold text-texts-secondary300'
                        }
                    />
                </div>
            </div>
        </Modal>
    );
};
