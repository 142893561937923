export const supportedFonts = Object.freeze({
    segoe: { class: 'font-segoe', name: 'Segoe UI' },
    sans: { class: 'font-publicSans', name: 'Public Sans' },
    inter: { class: 'font-inter', name: 'Inter' },
    poppins: { class: 'font-poppins', name: 'Poppins' },
    roboto: { class: 'font-roboto', name: 'Roboto' },
    serif: { class: 'font-serif', name: 'Merriweather' },
    mono: { class: 'font-mono', name: 'Fira Code' },
    lato: { class: 'font-lato', name: 'Lato' },
    montserrat: { class: 'font-montserrat', name: 'Montserrat' },
    ubuntu: { class: 'font-ubuntu', name: 'Ubuntu' },
});
