import { useState, useEffect, useRef } from 'react';
import { Button } from '../reusableComponents';
import { faPlay, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';

export const TextReader = ({
    audioURLArray,
    id,
    activeId,
    enableSpeaker,
    setEnableSpeaker,
    enableReader,
    setActiveAudioId,
    setEnableScrollToBottom,
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    useEffect(() => {
        if (id && audioURLArray && audioURLArray.length > 0) {
            if (activeId === id && enableSpeaker && enableReader) {
                playChunk(0);
            } else {
                if (isPlaying) {
                    stopAudio();
                }
            }
        }
    }, [audioURLArray, activeId, enableSpeaker, enableReader, id]);

    const playChunk = async (index) => {
        if (index >= audioURLArray.length) {
            setIsPlaying(false);
            setActiveAudioId(null);
            setEnableSpeaker(false);
            return;
        }

        // console.log('Playing chunk index:', index);
        if (audioRef.current) {
            audioRef.current.pause();
        }

        const newAudio = new Audio(audioURLArray[index]);
        audioRef.current = newAudio;

        newAudio.addEventListener('ended', () => {
            playChunk(index + 1);
        });

        try {
            await newAudio.play();
            if (!isPlaying) {
                setIsPlaying(true);
            }
        } catch (error) {
            console.warn('Audio play failed:', error);
            setIsPlaying(false);
        }
    };

    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        setIsPlaying(false);
        setEnableSpeaker(false);
    };

    const togglePlayer = () => {
        if (isPlaying) {
            setActiveAudioId(null);
            stopAudio();
        } else {
            setEnableScrollToBottom(false);
            setActiveAudioId(id);
            playChunk(0);
        }
    };

    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
        };
    }, []);

    return (
        <Button
            text={null}
            className={isPlaying ? 'animate-pulse' : ''}
            hoverText={isPlaying ? 'Stop Reader' : 'Start Reader'}
            disabled={!enableReader && audioURLArray.length > 0}
            onClick={togglePlayer}
            icon={isPlaying ? faVolumeHigh : faPlay}
            type={'success'}
        />
    );
};
